import * as React from 'react';

import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/styles';

import { Button } from '@material-ui/core';
import Link from './Link';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    pagenation: {
        display: `flex`,
        justifyContent: 'center',
        overflowX: 'auto',
    },
    page: {
        flex: 0
    }
}));

const ListPagenation = ({ category, pageNo, lastPageNo }) => {
    const classes = useStyles()
    const path = category ? `/${category}` : `/blog`

    return (
        <Toolbar
            component="nav"
            variant="dense"
            className={classes.pagenation}
        >
            {
                Array.from({ length: lastPageNo }, (_, pageIdx) => pageIdx + 1).map((curPageNo) => (
                    curPageNo === pageNo
                        ? <Button className={classes.page} variant="outlined" disabled>{curPageNo}</Button>
                        : <Link to={curPageNo > 1 ? `${path}/${curPageNo}` : `${path}`}>
                            <Button className={classes.page} variant="outlined">
                                {curPageNo}
                            </Button>
                        </Link>

                ))
            }

        </Toolbar >)
}

ListPagenation.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }),
    ),
    previous: PropTypes.object,
    next: PropTypes.object,
};

export default ListPagenation;
